<template>
  <CContainer>
    <CRow v-if="listaUsuario.length > 0">
      <CCol col="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol col="4">
                <label type="text" class="col-sm-9"
                  ><span>
                    <strong>Cuentas</strong>
                  </span></label
                >
                <v-select @input="setSelected" :options="listaUsuario" />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between">
              <CCol col="8"><h3>Arbol de Usuarios</h3></CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fields"
              @page-change="pageChange"
              :loading="loading_tabla_2"
            >
              <template #saldo="{ item, index }">
                <td>
                  {{ $formatterNumeroTipoCreditos(item.saldo, 1) }}
                </td>
              </template>
              <template #status="{ item, index }">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    {{ item.status == 1 ? "Activo" : "Inactivo" }}
                  </CBadge>
                </td>
              </template>
            </CDataTable>
            <CRow>
              <CCol col="6">
                <strong>Total Usuarios:</strong>
                {{ $formatterNumeroTipoCreditos(items.length) }}
              </CCol>
              <CCol col="6">
                <strong>Total Saldo:</strong>
                {{ $formatterNumeroTipoCreditos(saldoTotal, 1) }}
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </CContainer>
</template>

<script>
import vSelect from "vue-select";
export default {
  name: "ArbolUsuarios",
  components: { vSelect },
  data() {
    return {
      mensaje_success: "",
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      historial_select: "sms",
      items: [],
      items_filter: [],
      fields: [
        { key: "id", label: "ID" },
        { key: "email", label: "Email" },
        { key: "saldo", label: "Saldo Actual" },
        { key: "generacion", label: "Generacion" },
        { key: "padre_email", label: "Padre Email" },
        { key: "status", label: "Estado" },
      ],
      activePage: 1,
      items_cargando: [],
      activePage_cargando: 1,
      usuario_selected: 0,
      loading_tabla_2: false,
      listaUsuario: [],
    };
  },
  computed: {
    saldoTotal() {
      let suma = 0;
      for (const iterator of this.items) {
        suma += parseFloat(iterator.saldo);
      }
      return suma;
    },
  },
  methods: {
    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
      }
    },
    setSelected(value) {
      this.obtenerArbolUsuarios(value);
    },
    obtenerArbolUsuarios(email) {
      this.loading_tabla_2 = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post("/sms/usuarios/arbol", {
          email: email,
        })
        .then((response) => {
          this.items = response.data;
          this.items.map((item, id) => {
            if (id % 2 == 0) {
              item["_classes"] = "table-now";
            }
            return item;
          });

          this.loading_tabla_2 = false;
          //console.log(response);
        })
        .catch((e) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => {
          this.$store.dispatch("auth/loading_http", "hidden");
        });
    },

    validator(val) {
      return val ? val.length >= 4 : false;
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    pageChange_cargando(val) {
      this.$router.push({ query: { page: val } });
    },
    filtroUsuariosChange() {
      this.obtenerHistorialEnvios(this.usuario_selected);
      this.obtenerHistorialEnviosProcesando(this.usuario_selected);
    },
    obtenerUsuarios() {
      this.$http
        .get("/sms/usuarios")
        .then((response) => {
          this.listaUsuario = response.data;
          let data = response.data;
          if (data.length > 0) {
            this.desahabilitarModoEnvioSubCuenta = false;
            this.listaUsuario = [];
            this.usuario_selected = this.$store.state.auth.user.id;
            for (const iterator of data) {
              this.listaUsuario.push(iterator.email);
            }
          } else {
            this.listaUsuario = [];
            this.usuario_selected = 0;
          }
        })
        .catch((e) => {});
    },
  },
  mounted() {
    setTimeout(() => {
      //this.obtenerArbolUsuarios();
      this.obtenerUsuarios();
    }, 300);
  },
};
</script>
